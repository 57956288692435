<template>
  <v-form @submit.prevent="updateItem" ref="form">
    <v-card>
      <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
      >
        <span v-if="packageItem">Edit package</span>
        <span v-if="!packageItem">Create package</span>
        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2 px-6">
        <v-overlay
            :absolute="true"
            :value="dataLoading"
            justify-center
            align-center
            opacity="0.15"
        >
          <v-progress-circular
              size="64"
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col>
            <v-text-field
                name="packageName"
                :label="attributeLabels.name"
                v-model="packageForm.packageName"
                type="text"
                :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  (v && v.length >= 3) || 'Should contains at least 3 symbols',
                (v) =>
                  (v && v.length <= 20) || 'Should contains maximum 20 symbols',
              ]"
                :counter="20"
                validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>

        <v-text-field
            name="description"
            :label="attributeLabels.description"
            v-model="packageForm.description"
            type="text"
            :rules="[
            (v) => !!v || 'Required',
            (v) => (v && v.length >= 3) || 'Should contains at least 3 symbols',
            (v) =>
              (v && v.length <= 250) || 'Should contains maximum 20 symbols',
          ]"
            validate-on-blur
            :counter="250"
        ></v-text-field>
        <v-row class="mb-5">
          <v-col>
            <v-text-field
                name="price"
                :label="attributeLabels.price"
                v-model="packageForm.price"
                type="text"
                :rules="[(v) => !!v || v === 0 || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                name="billingPeriod"
                :label="attributeLabels.billingPeriod"
                v-model="packageForm.billingPeriod"
                type="text"
                :rules="[(v) => !!v || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                name="usersLimit"
                :label="attributeLabels.usersLimit"
                v-model="packageForm.usersLimit"
                type="text"
                :rules="[(v) => !!v || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                name="activeProjectsLimit"
                :label="attributeLabels.activeProjectsLimit"
                v-model="packageForm.activeProjectsLimit"
                type="text"
                :rules="[(v) => !!v || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col>
            <v-text-field
                name="activeFeedProjectsLimit"
                :label="attributeLabels.activeFeedProjectsLimit"
                v-model="packageForm.activeFeedProjectsLimit"
                type="text"
                :rules="[
  (v) => v !== null && v !== undefined && v !== '' || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col v-if="packageForm.activeFeedProjectsLimit > 0">
            <v-text-field
                name="feedSourcesLimit"
                :label="attributeLabels.feedSourcesLimit"
                v-model="packageForm.feedSourcesLimit"
                type="text"
                :rules="[(v) => !!v || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col v-else>
            <v-spacer></v-spacer>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <div class="mb-5">
          <label
              class="v-label v-label--active theme--light text-body-2 mb-0 pb-0"
          >Select one of the options:</label
          >
          <v-row no-gutters>
            <v-col cols="4">
              <v-checkbox
                  v-model="packageForm.upgradable"
                  label="Parent package"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                  class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                  v-model="packageForm.extended"
                  label="Child package"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                  class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="packageForm.extended">
            <v-col cols="6">
              <v-autocomplete
                  :items="parentItems"
                  item-text="title"
                  item-value="value"
                  menu-props="closeOnContentClick"
                  hide-no-data
                  hide-selected
                  name="parentId"
                  :label="attributeLabels.parentId"
                  v-model="packageForm.parentId"
                  type="text"
                  :rules="[(v) => !!v || 'Required']"
              />
            </v-col>
          </v-row>
        </div>
        <label class="v-label v-label--active theme--light text-body-2">{{
            attributeLabels.allowedSourceTypes
          }}</label>
        <v-row no-gutters>
          <v-col v-for="(source, index) in availableSourceTypes" :key="index">
            <v-checkbox
                v-model="packageForm.allowedSourceTypes"
                :label="source"
                :value="source"
                hide-details
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mb-5">
          <v-col>
            <v-checkbox
                v-model="packageForm.allowCustomSources"
                :label="attributeLabels.allowCustomSources"
                :true-value="1"
                :false-value="0"
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col v-if="packageForm.allowCustomSources">
            <v-text-field
                name="customSourcesLimit"
                :label="attributeLabels.customSourcesLimit"
                v-model="packageForm.customSourcesLimit"
                type="text"
                :rules="[(v) => !!v || 'Required', isNumeric]"
                validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
            name="monitoringFrequency"
            :label="attributeLabels.monitoringFrequency"
            v-model="packageForm.monitoringFrequency"
            type="text"
            :rules="[(v) => !!v || 'Required', isNumeric]"
            validate-on-blur
        ></v-text-field>
        <v-row class="mb-5" no-gutters>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.allowGlobalSearch"
                :label="attributeLabels.allowGlobalSearch"
                :true-value="true"
                :false-value="false"
                hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.allowReports"
                :label="attributeLabels.allowReports"
                :true-value="true"
                :false-value="false"
                hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.allowCategories"
                :label="attributeLabels.allowCategories"
                :true-value="true"
                :false-value="false"
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.allowMentions"
                :label="attributeLabels.allowMentions"
                :true-value="true"
                :false-value="false"
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.isVisible"
                :label="attributeLabels.isVisible"
                :true-value="true"
                :false-value="false"
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.allowAnalytics"
                :label="attributeLabels.allowAnalytics"
                :true-value="true"
                :false-value="false"
                hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.allowImport"
                :label="attributeLabels.allowImport"
                :true-value="true"
                :false-value="false"
                hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
                v-model="packageForm.googleSearch"
                :label="attributeLabels.googleSearch"
                :true-value="true"
                :false-value="false"
                hide-details
            />
          </v-col>
          <v-col cols="6" v-if="(packageForm.allowCustomSources || packageForm.feedSourcesLimit > 0) && packageForm.allowedSourceTypes.includes('youtube')">
            <v-checkbox
                v-model="packageForm.youtubeSubtitles"
                :label="attributeLabels.youtubeSubtitles"
                :true-value="true"
                :false-value="false"
                hide-details
            />
          </v-col>
        </v-row>

        <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
          <div v-for="(error, key) in errors" :key="key">
            {{ prepareErrorMessage(error, key) }}
          </div>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="primary" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import { SAVE_PACKAGE } from "@/store/modules/subscriptionPackage/actions";
import { FETCH_PACKAGE_DICTIONARY } from "@/store/modules/account/actions";

const defaultValues = {
  packageName: null,
  description: null,
  billingPeriod: 30,
  upgradable: true,
  extended: false,
  price: null,
  usersLimit: null,
  activeProjectsLimit: null,
  activeFeedProjectsLimit: null,
  allowedSourceTypes: [],
  allowCustomSources: 0,
  customSourcesLimit: 0,
  feedSourcesLimit: 0,
  monitoringFrequency: null,
  allowGlobalSearch: false,
  allowReports: false,
  allowImport: false,
  googleSearch: false,
  allowCategories: false,
  allowMentions: false,
  isVisible: false,
  parentId: null,
};

export default {
  name: "SubscriptionPackageForm",
  props: {
    packageItem: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      availableSourceTypes: [
        "media",
        "facebook",
        "tiktok",
        "twitter",
        "telegram",
        "youtube",
        "linkedin",
      ],
      packageForm:
          this.packageItem && this.packageItem.billingPeriod
              ? {
                packageName: this.packageItem.name,
                description: this.packageItem.description,
                billingPeriod: this.packageItem.billingPeriod,
                price: this.packageItem.price,
                usersLimit: this.packageItem.usersLimit,
                activeProjectsLimit: this.packageItem.activeProjectsLimit,
                activeFeedProjectsLimit: this.packageItem.activeFeedProjectsLimit,
                allowedSourceTypes: this.packageItem.allowedSourceTypes,
                allowCustomSources: this.packageItem.allowCustomSources,
                customSourcesLimit: this.packageItem.customSourcesLimit,
                feedSourcesLimit: this.packageItem.feedSourcesLimit,
                monitoringFrequency: this.packageItem.monitoringFrequency,
                allowGlobalSearch: this.packageItem.allowGlobalSearch,
                allowAnalytics: this.packageItem.allowAnalytics,
                allowReports: this.packageItem.allowReports,
                allowImport: this.packageItem.allowImport,
                googleSearch: this.packageItem.googleSearch,
                youtubeSubtitles: this.packageItem.youtubeSubtitles,
                allowCategories: this.packageItem.allowCategories,
                allowMentions: this.packageItem.allowMentions,
                isVisible: this.packageItem.isVisible,
                upgradable: this.packageItem.upgradable,
                extended: this.packageItem.extended,
                parentId: this.packageItem.parentId,
              }
              : _.merge({}, defaultValues),
      birthdayModal: false,
      attributeLabels: {
        name: "Package name",
        description: "Description",
        billingPeriod: "Billing period",
        price: "Price",
        usersLimit: "Users Limit",
        activeProjectsLimit: "Projects Limit",
        activeFeedProjectsLimit: "Feed projects Limit",
        allowedSourceTypes: "Source types",
        allowCustomSources: "Include custom sources",
        customSourcesLimit: "Sources limit",
        feedSourcesLimit: "Feed sources limit",
        monitoringFrequency: "Monitoring frequency",
        allowGlobalSearch: "Allowed Global Search",
        allowAnalytics: "Allowed Analytics",
        allowReports: "Allowed  Export reports",
        allowImport: "Allowed Import",
        googleSearch: "Google Search",
        youtubeSubtitles: "Youtube Subtitles",
        allowCategories: "Allowed Categories",
        allowMentions: "Allowed Mentions",
        isVisible: "Visible package",
        parentId: "Parent package",
      },
      errors: {},
      parentItems: [],
    };
  },
  computed: {
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  watch: {
    "packageForm.upgradable": function () {
      if (this.packageForm.upgradable) {
        this.packageForm.extended = false;
      }
    },
    "packageForm.extended": function () {
      if (this.packageForm.extended) {
        this.packageForm.upgradable = false;
      }
    },
  },
  methods: {
    isNumeric: function (v) {
      return (
          (Number(v) !== false &&
              (Number.isInteger(Number(v)) || v === "0") &&
              Number(v) >= 0) ||
          "Should be a valid number"
      );
    },
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { packageForm } = this;
        this.$store
            .dispatch("subscriptionPackage/" + SAVE_PACKAGE, {
              data: packageForm,
              id: this.packageItem ? this.packageItem.id : null,
            })
            .then(
                (response) => {
                  this.dataLoading = false;
                  if (response.result) {
                    this.packageForm = defaultValues;
                    this.$emit("submitted", response.data);
                    this.errors = {};
                  } else {
                    this.errors = response.details;
                  }
                },
                (err) => {
                  console.log("Err", err);
                }
            )
            .catch(() => {});
      }
    },
    fetchPackages: function () {
      this.isLoading = true;
      this.$store
          .dispatch("account/" + FETCH_PACKAGE_DICTIONARY)
          .then(
              (payload) => {
                this.isLoading = false;
                this.parentItems = payload.data.filter((item) => item.upgradable);
              },
              () => {}
          )
          .catch(() => {});
    },
  },
  created: function () {
    this.fetchPackages();
  },
};
</script>
